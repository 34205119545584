import {
  AspectRatio,
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Grid,
  GridItem,
  Heading,
  HStack,
  Image,
  LinkBox,
  LinkOverlay,
  Stack,
  Text,
} from '@chakra-ui/react'
import { useIntl } from 'react-intl'
import { Link } from '../Link'

import { FiExternalLink } from 'react-icons/fi'
import EmptyCartBlog1 from '/public/img/empty-cart-blog-1.jpg'
import EmptyCartBlog2 from '/public/img/empty-cart-blog-2.jpg'
import EmptyCartBlog3 from '/public/img/empty-cart-blog-3.jpg'

const blogEntry1 = {
  id: 1,
  author: {
    name: 'Betsy Greer',
    url: 'https://blog.spoonflower.com/author/bgreer',
  },
  category: {
    name: 'cart.emptyState.blog1.category.name',
    url: 'https://blog.spoonflower.com/category/interior-design',
  },
  imageSrc: EmptyCartBlog1.src,
  text: 'cart.emptyState.blog1.text',
  title: 'cart.emptyState.blog1.title',
  url: 'https://blog.spoonflower.com/2023/04/18/spoonflower-wallpaper',
}

const blogEntry2 = {
  id: 2,
  author: {
    name: 'Amy Hoppe',
    url: 'https://blog.spoonflower.com/author/ahoppe',
  },
  category: {
    name: 'cart.emptyState.blog2.category.name',
    url: 'https://blog.spoonflower.com/category/inspiration/handmade-apparel',
  },
  imageSrc: EmptyCartBlog2.src,
  text: 'cart.emptyState.blog2.text',
  title: 'cart.emptyState.blog2.title',
  url: 'https://blog.spoonflower.com/2021/05/17/making-your-own-clothes',
}

const blogEntry3 = {
  id: 3,
  author: {
    name: 'Kate Schanz',
    url: 'https://blog.spoonflower.com/author/kateschanz',
  },
  category: {
    name: 'cart.emptyState.blog3.category.name',
    url: 'https://blog.spoonflower.com/category/inspiration/wallpaper',
  },
  imageSrc: EmptyCartBlog3.src,
  text: 'cart.emptyState.blog3.text',
  title: 'cart.emptyState.blog3.title',
  url: 'https://blog.spoonflower.com/2023/01/24/wallpaper-installation-tips',
}

interface BlogArticlesProps {
  title: string
}

const BlogArticles: React.FC<BlogArticlesProps> = (props) => {
  const intl = useIntl()
  const { title } = props

  return (
    <>
      <Heading
        as="h3"
        size={{ base: 'lg', 'sp-md': '2xl', lg: 'xl', xl: '2xl' }}
        textAlign={{ 'sp-md': 'center' }}
        variant="serif"
      >
        {title}
      </Heading>

      <Grid
        gap={{ base: '16', md: '6' }}
        gridTemplateColumns={{
          base: 'repeat(1, 1fr)',
          md: 'repeat(2, 1fr)',
          lg: 'repeat(3, 1fr)',
        }}
        marginTop={{ base: '6', 'sp-md': '8' }}
      >
        {[blogEntry1, blogEntry2, blogEntry3].map(
          ({ id, category, author, imageSrc, text, title, url }) => (
            <GridItem key={id}>
              <LinkBox height="full">
                <Card
                  height="full"
                  variant={{ base: 'unstyled', xl: 'outline' }}
                  direction={{ base: 'column', 'sp-sm': 'row', md: 'column' }}
                  bg={{ base: 'transparent', xl: 'white' }}
                >
                  <CardHeader
                    width={{ 'sp-sm': '45%', md: '100%' }}
                    padding={{ xl: '0' }}
                  >
                    <LinkOverlay href={url} target="_blank">
                      <AspectRatio
                        ratio={{ base: 4 / 3, 'sp-sm': 1, md: 4 / 3 }}
                      >
                        <Image
                          borderTopRadius="md"
                          borderBottomRadius={{ base: 'md', xl: 'none' }}
                          src={imageSrc}
                          objectFit="cover"
                          alt={intl.formatMessage({ id: title })}
                        />
                      </AspectRatio>
                    </LinkOverlay>
                  </CardHeader>

                  <Stack
                    height="full"
                    width={{ 'sp-sm': '55%', md: '100%' }}
                    paddingLeft={{ 'sp-sm': '4', 'sp-md': '6', md: 0 }}
                    alignItems={{ base: 'space-between' }}
                  >
                    <CardBody
                      paddingY={{ base: '4!', 'sp-sm': '0!', md: '4!' }}
                      paddingX={{ xl: '3' }}
                    >
                      <Box marginBottom="3">
                        <Link
                          href={category.url}
                          marginBottom="2"
                          target="_blank"
                        >
                          <Text as="span" fontWeight="semibold" size="sm">
                            {intl.formatMessage({ id: category.name })}
                          </Text>
                        </Link>
                      </Box>

                      <Heading
                        as="h6"
                        color="gray.900"
                        fontWeight="regular"
                        marginBottom="4"
                        size="md"
                        variant="serif"
                        noOfLines={2}
                      >
                        {intl.formatMessage({ id: title })}
                      </Heading>

                      <Text as="p" color="gray.600" noOfLines={2} size="md">
                        {intl.formatMessage({ id: text })}
                      </Text>
                    </CardBody>

                    <CardFooter
                      paddingTop={{ base: '2' }}
                      paddingBottom={{ xl: '6' }}
                      marginTop={{ xl: '0!' }}
                    >
                      <HStack
                        fontSize={{ base: 'xs', lg: 'sm' }}
                        justifyContent="space-between"
                        width="full"
                      >
                        <HStack spacing="1">
                          <Text as="span" size="sm">
                            {intl.formatMessage({
                              id: 'cart.emptyState.blog.by',
                            })}
                          </Text>

                          <Link href={author.url} target="_blank">
                            <Text
                              as="span"
                              noOfLines={1}
                              fontWeight="semibold"
                              size="sm"
                            >
                              {author.name}
                            </Text>
                          </Link>
                        </HStack>
                      </HStack>
                    </CardFooter>
                  </Stack>
                </Card>
              </LinkBox>
            </GridItem>
          )
        )}
      </Grid>

      <Stack
        direction={{ base: 'column', lg: 'row' }}
        alignItems={{ 'sp-md': 'center' }}
        justifyContent={{ lg: 'center' }}
        marginTop={{ base: '8' }}
        spacing={{ base: '4', lg: '8' }}
      >
        <Button
          as={Link}
          color="primary-text"
          colorScheme="white"
          href="https://blog.spoonflower.com"
          size={{ base: 'md', 'sp-md': 'lg' }}
          target="_blank"
          variant="outline"
          width={{ base: 'full' }}
          maxWidth={{ 'sp-md': '200' }}
        >
          <HStack spacing="2">
            <Text as="span">
              {intl.formatMessage({ id: 'cart.emptyState.blog.seeAllButton' })}
            </Text>

            <FiExternalLink aria-label="Open in new tab" />
          </HStack>
        </Button>
      </Stack>
    </>
  )
}

export default BlogArticles
